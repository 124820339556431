<template>
  <div>
    <div class="header" v-if="localDelivery">
      <div class="title">
        {{ convertFExpressCompany(localDelivery.fExpressCompany)
        }}{{ $t("快递单号") }}:
        {{ localDelivery.fTrackingNo }}
      </div>
      <div class="secondary-text">
        {{ $t(convertLocalDeliveryStatus(localDelivery.status)) }}
        {{ formatLocalDeliveryStatusTime(localDelivery) }}
      </div>
    </div>

    <van-cell-group
      v-if="localDelivery"
      :title="
        $t('共count件包裹', {
          count: localDelivery.cargoList ? localDelivery.cargoList.length : 0,
        })
      "
    >
      <div v-for="cargo in localDelivery.cargoList" :key="cargo.id">
        <van-cell
          :title="cargo.trackingNo"
          :value="cargo.remark ? cargo.remark : ''"
          :to="{ name: 'cargoDetail', query: { id: cargo.id } }"
          is-link
        ></van-cell>
      </div>
    </van-cell-group>

    <van-cell-group :title="$t('收件信息')">
      <van-cell
        :title="$t('收件人')"
        :value="
          localDelivery
            ? `${localDelivery.receiverName} / ${localDelivery.receiverMobile}`
            : ''
        "
      ></van-cell>
      <van-cell
        title-width="70px"
        :title="$t('地址')"
        :value="
          localDelivery
            ? `${localDelivery.receiverAddress} / ${localDelivery.receiverPostalCode}`
            : ''
        "
      ></van-cell>
    </van-cell-group>

    <div class="panel">
      <van-steps desc-class="vant-step-desc" direction="vertical">
        <van-step v-for="step in steps" :key="step.id">
          <span style="font-size: 1rem">{{ step.text }}</span>
          <br />
          <span style="display: inline-block">{{ step.desc }}</span>
        </van-step>
      </van-steps>
    </div>

    <div
      v-if="localDelivery && localDelivery.custReceivedImage"
      class="image-wrapper"
    >
      <van-image :src="localDelivery.custReceivedImage" fit="cover" />
    </div>
  </div>
</template>

<script>
import {
  convertFExpressCompany,
  convertLocalDeliveryStatus,
} from "@/utils/dict";
import { formatTime, formatLocalDeliveryStatusTime } from "@/utils/utils";

import { getLocalDeliveryDetail } from "@/service/api";

import { Image as VanImage, Cell, CellGroup, Step, Steps, Toast } from "vant";
import { getLocale } from "@/utils/storage";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  data() {
    return {
      id: this.$route.query.id,
      localDelivery: null,
      steps: [],
    };
  },
  mounted() {
    this.getLocalDeliveryDetail();
  },
  methods: {
    formatLocalDeliveryStatusTime(value) {
      return formatLocalDeliveryStatusTime(value);
    },
    convertFExpressCompany(value) {
      return convertFExpressCompany(value);
    },
    convertLocalDeliveryStatus(value) {
      return convertLocalDeliveryStatus(value);
    },
    async getLocalDeliveryDetail() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await getLocalDeliveryDetail(this.id);
      let steps = [];
      if (res.success && res.data.routes && res.data.routes.length) {
        // const newRoutes = res.data.routes.filter((item) => item.kd100Flag == 3);

        const locale = getLocale();

        steps = res.data.routes.map((item) => {
          let text;

          switch (locale) {
            case "zh-TW":
              text = item.title ? item.title : item.description;
              break;
            case "th-TH":
              text = item.localTitle ? item.localTitle : item.localDescription;
              break;
            default:
              text = item.enTitle ? item.enTitle : item.enDescription;
              break;
          }

          text = text ? text : item.enTitle ? item.enTitle : item.enDescription;
          return {
            text: text,
            desc: formatTime(item.createdAt),
          };
        });
      }
      this.localDelivery = res.data;
      this.steps = steps;

      Toast.clear();
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  background-color: #fff;
  padding: 15px;
}
.header .title {
  font-size: 1.1rem;
}

.image-wrapper {
  padding: 10px 0px;
  text-align: center;
}

.van-cell__value {
  min-width: 70%;
}
</style>