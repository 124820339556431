var EXPRESS_WAY_DICT = {
  '1': '陆运',
  '2': '海运'
}
var CARGO_TYPE_DICT = {
  'A': '普货',
  'B': '敏感货',
  'C': '特殊货'
}
var PAYMENT_STATUS_DICT = {
  '1': '待支付',
  '2': '已支付'
}
var BILLING_METHOD_DICT = {
  '1': '按重量',
  '2': '按体积'
}

var CARGO_STATUS_DICT = {
  '1': '等待入仓',
  '2': '到达中转仓',
  '3': '发往泰国',
  '4': '到达泰国仓',
  '5': '到达泰国仓',
  '8': '已揽收',  
  '6': '派送上门',
  '7': '您已签收',
  '10': '已退回泰国仓',
  '12': '已取消'
}
var LOCAL_DELIVERY_STATUS_DICT = {
  // '0': '待打包',
  // '1': '已打包',
  '0': '出库中',
  '1': '出库中',
  '4': '发往泰国',
  '6': '到达泰国仓',
  '8': '已揽收',  
  '2': '派送上门',
  '3': '您已签收',
  '10': '已退回泰国仓',
  '12': '已取消'
}
var F_EXPRESS_COMPANY = {
  'jt': 'J&T',
  'flash': 'Flash',
  'Grab': 'Grab',
  'Staff': '小蜜蜂',
  'OnSite': '自提',
  'zto': 'ZTO',
  'self_pick_up': '自提'
}

const order_type = {
  "1": "转运费",
  "7": "用户余额充值",
  "8": "运费返还",
  "99": "运单赔付"
}

var ISSUE_TYPE = {
  'insurance': '保险',
  'delivery_fee_on_receiver': '国内快递费到付',
  'woodenframe': '打木架'
}

function convertOrderType(val) {
  return order_type[val + '']
}

function convertExpressWay(val) {
  return EXPRESS_WAY_DICT[val + '']
}
function convertCargoType(val) {
  return CARGO_TYPE_DICT[val]
}
function convertCargoStatus(val, paymentStatus) {
  if ((val == 4 || val == 5) && paymentStatus == 2) {
    return '安排配送中'
  }
  return CARGO_STATUS_DICT[val + '']
}
function convertLocalDeliveryStatus(val) {
  return LOCAL_DELIVERY_STATUS_DICT[val + '']
}
function convertFExpressCompany(val) {
  if (val) {
    if (F_EXPRESS_COMPANY[val]) {
      return F_EXPRESS_COMPANY[val]
    } else {
      return val;
    }
  }
  return '';
}
function convertPaymentStatus(val) {
  return PAYMENT_STATUS_DICT[val + '']
}
function convertBillingMethod(val) {
  return BILLING_METHOD_DICT[val + '']
}
function convertIssueType(val) {
  return ISSUE_TYPE[val + '']
}

module.exports = {
  convertExpressWay: convertExpressWay,
  convertCargoType: convertCargoType,
  convertCargoStatus: convertCargoStatus,
  convertPaymentStatus: convertPaymentStatus,
  convertBillingMethod: convertBillingMethod,
  convertLocalDeliveryStatus: convertLocalDeliveryStatus,
  convertFExpressCompany: convertFExpressCompany,
  convertOrderType: convertOrderType,
  convertIssueType: convertIssueType
};