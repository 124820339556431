var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.localDelivery)?_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.convertFExpressCompany(_vm.localDelivery.fExpressCompany))+_vm._s(_vm.$t("快递单号"))+": "+_vm._s(_vm.localDelivery.fTrackingNo)+" ")]),_c('div',{staticClass:"secondary-text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.convertLocalDeliveryStatus(_vm.localDelivery.status)))+" "+_vm._s(_vm.formatLocalDeliveryStatusTime(_vm.localDelivery))+" ")])]):_vm._e(),(_vm.localDelivery)?_c('van-cell-group',{attrs:{"title":_vm.$t('共count件包裹', {
        count: _vm.localDelivery.cargoList ? _vm.localDelivery.cargoList.length : 0,
      })}},_vm._l((_vm.localDelivery.cargoList),function(cargo){return _c('div',{key:cargo.id},[_c('van-cell',{attrs:{"title":cargo.trackingNo,"value":cargo.remark ? cargo.remark : '',"to":{ name: 'cargoDetail', query: { id: cargo.id } },"is-link":""}})],1)}),0):_vm._e(),_c('van-cell-group',{attrs:{"title":_vm.$t('收件信息')}},[_c('van-cell',{attrs:{"title":_vm.$t('收件人'),"value":_vm.localDelivery
          ? `${_vm.localDelivery.receiverName} / ${_vm.localDelivery.receiverMobile}`
          : ''}}),_c('van-cell',{attrs:{"title-width":"70px","title":_vm.$t('地址'),"value":_vm.localDelivery
          ? `${_vm.localDelivery.receiverAddress} / ${_vm.localDelivery.receiverPostalCode}`
          : ''}})],1),_c('div',{staticClass:"panel"},[_c('van-steps',{attrs:{"desc-class":"vant-step-desc","direction":"vertical"}},_vm._l((_vm.steps),function(step){return _c('van-step',{key:step.id},[_c('span',{staticStyle:{"font-size":"1rem"}},[_vm._v(_vm._s(step.text))]),_c('br'),_c('span',{staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(step.desc))])])}),1)],1),(_vm.localDelivery && _vm.localDelivery.custReceivedImage)?_c('div',{staticClass:"image-wrapper"},[_c('van-image',{attrs:{"src":_vm.localDelivery.custReceivedImage,"fit":"cover"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }